import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useQueueServiceList() {
    const refListTable = ref();
    const items = ref([]);
    const suggestions = ref([]);
    const patients = ref();
    const refFormObserver = ref();
    const Doctor_Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const Room_Data = ref([]);
    const toast = useToast()
    const DateNow = ref( new Date().toISOString());
    const statuses = [
      {
        id:1,
        title:'รอรับการรักษา'
      },
      {
        id:2,
        title:'รับการรักษา'
      },
      {
        id:3,
        title:'ยกเลิก'
      }
    ]
    const printData = ref({});
   
    const tableColumns = [
       // { field: 'no', label: '#', sortable: false},
        { field: 'opd_code', label: 'เลขที่ OPD', sortable: false},
        { field: 'service_no', label: 'เลขที่บริการ', sortable: false},
        { field: 'date', label: 'วันที่', sortable: false},
        { field: 'full_name', label: 'ลูกค้า', sortable: false},
        { field: 'doctor_full_name', label: 'แพทย์', sortable: false },
        { field: 'room_name', label: 'ห้อง', sortable: false },
        { field: 'status_name', label: 'สถานะ', sortable: false },
    
        { field: 'action', label: 'ตัวเลือก', sortable: false },



    ]



    let inpt = {
        opdcard_id:'',
        patients_id:'',
        doctor_id:'',
        room_id:'',
        status:1,
        branch_id:branch_id,
        created_by:userStorage.id,
        date: new Date().toISOString().slice(0,10)

    }
    const inputData = ref(JSON.parse(JSON.stringify(inpt)));
   /* let section = {
        patients: {
            limit: 6,
            label: 'Patients',
                onSelected: selected => {
                    console.log('inputProps',selected);
                
                    //this.selected = selected.item
            },
        },
    }
    const sectionConfigs = ref(JSON.parse(JSON.stringify(section)));*/
    let inpprop =  {
        
        id: 'autosuggest__input_ajax',
        placeholder: 'ชื่อลูกค้า',
        class: 'form-control',
        name: 'patients_id',
      }
    const inputProps = ref(JSON.parse(JSON.stringify(inpprop)));
    const currentPage = ref(1);
    const pageLength = ref(10);
    const Total = ref(0);
    const searchQuery = ref('');
    const searchDate = ref( new Date().toISOString().slice(0,10));
    const searchStatus = ref('');
    const vouchers = ref([]);
    
    watch([currentPage, pageLength,searchQuery,searchDate,searchStatus], () => {
      items.value = []
      fetchData();
    })
  
    const fetchData = async ()=>{
      const offset = pageLength.value*(currentPage.value-1);
      let response =  await store.dispatch('queue-store/fetchData', {
              // id:queue_id,
                limit: pageLength.value,
                offset:offset,
                keywords: searchQuery.value,
                date: searchDate.value,
                status: searchStatus.value,
                branch_id:branch_id,
                order_by:'id DESC',
                
            
              });
        if(response.data.success){
          items.value = response.data.data;

        }
       
      

    }
    fetchData();
    const  renderSuggestion = async (suggestion)=>{
        console.log('renderSuggestion',suggestion.item.name);
        return <div style={{ color: "red" }}>{suggestion.name}</div>;
    }
    const getSuggestionValue =  (suggestion)=>{
       
        const { name, item } = suggestion
        console.log('getSuggestionValue',item);
        return item.name;

    }
    const fetchResults = async(pl)=>{
        suggestions.value = []
        if(pl.length > 2){
            const patientsPromise = await store
                .dispatch('app-patients/fetchData', {
            
                keywords: pl,
            
                order_by:'patients.id DESC'
            
            });
            if(patientsPromise.data.success){
                Promise.all([patientsPromise]).then(values => {
                    var data =  values[0].data.data;
                    suggestions.value.push({ name: 'patients', data: data })
                });
              
            }

            
          
           // suggestions.value = itm;
            

        }else{

        }
       // console.log('suggestions',suggestions.value);
        //const { patients } = this

    }
    const fetchDoctorData = async () =>{
        Doctor_Data.value  = [];
        await store
              .dispatch('app-queue/fetchDoctorData', {
                //branch_id:branch_id,
                group_id:7,
                order_by:'id DESC'
            
              })
              .then(response => {
                console.log('doctor',response);
                Doctor_Data.value = response.data.data;
              })
              .catch((error) => {
                console.log('error',error);
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error fetching Doctor list",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
      }
      fetchDoctorData();

      const fetchRoomData = async () =>{
        Room_Data.value  = [];
        await store
              .dispatch('app-queue/fetchRoomData', {
                //branch_id:branch_id,
                is_active:1,
                order_by:'rooms.id DESC'
            
              })
              .then(response => {
                Room_Data.value = response.data.data;
              })
              .catch((error) => {
                console.log('error',error);
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error fetching Room list",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
      }
      fetchRoomData();
      const onSubmit = async ()=>{
      
    
        const result = await store.dispatch('queue-store/addData',inputData.value);
        if(result.data.success == true){
          let id = result.data.data.insertId;
          router.push({ name: 'queue-service-add',params:{id:id}, replace: true }).then(()=>{
            toast({
                component: ToastificationContent,
                props: {
                  title: "Create Service Success",
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
        });
        }else{
          toast({
            component: ToastificationContent,
            props: {
              title: "Create Serice  Error",
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })

        }
       
      }
      const getDetail = async (id)=>{
        let detail =    await store.dispatch('queue-store/getData',id);
        printData.value = detail.data.data;
         console.log('printData',printData.value);
       getVoucher( printData.value.patient_id);
        
      }
      const getVoucher = async (patient_id)=>{
        let params = {
            patients_id:patient_id

        }
        //console.log('params',params);
        store.dispatch('app-receipt/fetchPatientVoucherData',params).then(response=>{
         
            if(response.data.success){
                //vouchers.value.voucher_balance = response.data.data[0].balance*1;
                vouchers.value = response.data.data.map((value)=>{
                    value.used = 0;
                    value.balance = value.balance*1;
                    return value;

                });
               
               // console.log('payment',payment.value);
                //patient.value =response.data.data[0];
                
            }
            
          }).catch((err)=>{
            console.log('err',err);
           
      
          })
    }
     const resolveStatusVariant = (status,types) =>{

      if(types == 'print'){
        if(status == 2){
          return 'primary'

        }else{
          return 'mute'
        }

      }
      if(types == 'check'){
        if(status == 3){
          return 'mute'
        }else{
          return 'primary'
        }
      }
      
        return 'primary'
    }

    const resolveStatusTextVariant = status =>{
    const index =  statuses.findIndex(s => s.id === status)
    let text = 'รอรับการรักษา'
      if(index > -1){
        text = statuses[index].title;
      }
     
        return text
    }
    const cancelData = async(id)=>{
      
        let response =    await store.dispatch('queue-store/deleteData',id);
        if(response.data.success){
          toast({
            component: ToastificationContent,
            props: {
              title: "Cancel Service Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })

        }else{
          toast({
            component: ToastificationContent,
            props: {
              title: "Cancel Service Success",
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })

        }
        fetchData();

      
      }
    return {
        refListTable,
        tableColumns,
        items,
        currentPage,
        pageLength,
        Total,
       // sectionConfigs,
        inputProps,
        suggestions,
      //  renderSuggestion,
        fetchResults,
        getSuggestionValue,
        patients,
        inputData,
        refFormObserver,
        Doctor_Data,
        Room_Data,
        onSubmit,
        resolveStatusVariant,
        resolveStatusTextVariant,
        searchQuery,
        searchDate,
        searchStatus,
        statuses,
        DateNow,
        getDetail,
        printData,
        vouchers,
        cancelData
        
    }
}