<template>
    <div>
        <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        >
            <b-form
                class="form-opd-primary form-boder-input"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
            >
            <b-card no-body>
                    <div class="m-2">
                        <b-row>
                            <b-col
                            md="6"
                            class="d-flex justify-content-start"
                            
                            >
                            <h4 class="text-dark font-weight-bold">เพิ่มคิวบริการ</h4>
                            
                            </b-col>
                            <b-col
                            md="6"
                            class="d-flex justify-content-end"
                            >
                                <b-button  
                                type="submit"
                                variant="primary"
                                    >
                                    <feather-icon icon="PlusIcon" />
                                    เพิ่มคิว
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            
                            <b-col md="3">
                                <validation-provider
                                    #default="validationContext"
                                    name="patients"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <label>ชื่อลูกค้า/รหัส</label>
                                        <vue-autosuggest
                                        ref="autocomplete"
                                        name="patients"
                                        v-model="patients"
                                        :suggestions="suggestions"
                                        :input-props="inputProps"
                                        :section-configs="sectionConfigs"
                                        :render-suggestion="renderSuggestion"
                                        :get-suggestion-value="getSuggestionValue"
                                        @input="fetchResults"
                                    />    
                                
                                    </b-form-group>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                
                                </validation-provider>
                               
                            </b-col>
                            <b-col md="3">
                                <validation-provider
                                    #default="validationContext"
                                    name="doctor_id"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <label>เลือกแพทย์</label>
                                        <v-select
                                            :options="Doctor_Data"
                                            v-model="inputData.doctor_id"
                                            :get-option-label="(option) => option.first_name +' '+ option.last_name"
                                            :reduce="val => val.id"
                                            >
                                            <template #option="{ first_name,last_name }">
                                            {{ first_name }} {{ last_name }}
                                            <br />
                                            </template>
                                        </v-select>
                                        
                                    </b-form-group>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                
                                </validation-provider>
                               
                            </b-col>
                            <b-col md="3">
                                <validation-provider
                                    #default="validationContext"
                                    name="room_id"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <label>เลือกห้อง</label>
                                        <v-select
                                            :options="Room_Data"
                                            v-model="inputData.room_id"
                                            :get-option-label="(option) => option.title"
                                            :reduce="val => val.id"
                                            >
                                            <template #option="{ title }">
                                            {{ title }}
                                            <br />
                                            </template>
                                        </v-select>
                                        
                                    </b-form-group>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                
                                </validation-provider>
                               
                            </b-col>
                            <b-col md="3">
                                <div class="demo-vertical-spacing ml-1">
                                        <b-form-group
                                        label="วันที่"
                                        label-for="date"
                                        >
                                        <b-form-datepicker
                                            id="date"
                                            v-model="inputData.date"
                                            type="date"
                                        />
                                        </b-form-group>
                                    </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-form>
        </validation-observer>

        
        <b-card no-body>
            <div class="m-2">
                <b-row class="form-boder-input">
                    <b-col>
                        <b-form-group
                        label="เลขที่บริการ	"
                        label-for="service_no"
                        >
                       
      
                        <b-form-input
                            id="service_no"
                            v-model="searchQuery"
                            
                        />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                        label="วันที่"
                        label-for="date"
                        >  
        
                        <b-form-datepicker
                            id="searchDate"
                            v-model="searchDate"
                           
                            type="date"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label>สถานะ</label>
                            <v-select
                                :options="statuses"
                                v-model="searchStatus"
                                :get-option-label="(option) => option.title"
                                :reduce="val => val.id"
                                >
                               
                            </v-select>
                            
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <div class="m-2">
                <vue-good-table
                    :columns="tableColumns"
                    :rows="items"
                
                    ref="refListTable"
                    :select-options="{
                        enabled: false,
                        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                            enabled: true,
                            perPage:pageLength
                        }"
                
                    
                >
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                        <div v-if="props.column.field === 'opd_code'">
                          <span v-if="props.row.code">
                                {{  props.row.code }}
                          </span>
                          <span v-else>-</span>
                        </div>
                        <div v-else-if="props.column.field === 'service_no'">
                            <b-button
                 
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="flat-success"
                                class="btn-icon rounded-circle" 
                                
                                :to="{ name: 'queue-service-add', params:{id:props.row.id}}"

                            >
                            {{ props.row.service_no }}
                            </b-button>
                        </div>
                        <div v-else-if="props.column.field === 'status_name'">
                            
                            <span :class="props.row.status*1==3?'text-danger':''">{{  resolveStatusTextVariant(props.row.status) }}</span>

                        </div>
                        <div v-else-if="props.column.field === 'action'"  class="text-nowrap">
                                <b-button
                                    :id="`tooltip-target2-${props.row.id}`"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    :variant="`flat-${resolveStatusVariant(props.row.status, 'check')}`"
                                    class="btn-icon rounded-circle" 
                                    :to="{ name: 'queue-service-add', params:{id:props.row.id}}"

                                >
                                <feather-icon icon="CheckCircleIcon" />
                            </b-button>
                            <b-tooltip :target="`tooltip-target2-${props.row.id}`" triggers="hover" placement="top">
                                รักษา
                            </b-tooltip>
                            <b-button
                                    :id="`tooltip-target3-${props.row.id}`"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    :variant="`flat-${resolveStatusVariant(props.row.status,'print')}`"
                                    class="btn-icon rounded-circle" 
                                    @click="generateReport(props.row.id)"
                                    v-if="props.row.status == 2"
                                   

                                >
                                <feather-icon icon="PrinterIcon" />
                            </b-button>
                            <b-button
                                    :id="`tooltip-target3-${props.row.id}`"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    :variant="`flat-${resolveStatusVariant(props.row.status,'print')}`"
                                    class="btn-icon rounded-circle" 
                                   disabled
                                    v-else
                                   

                                >
                                <feather-icon icon="PrinterIcon" />
                            </b-button>
                            <b-tooltip :target="`tooltip-target3-${props.row.id}`" triggers="hover" placement="top">
                                ปริ้นท
                            </b-tooltip>
                            <b-button
                            v-if="props.row.status==3"
                    :disabled="true"
                    :id="`tooltip-target6-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="deleteconfirm(props.row.id)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                            <b-button
                            v-else
                    :disabled="false"
                    :id="`tooltip-target6-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="deleteconfirm(props.row.id)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                         
                <b-tooltip :target="`tooltip-target6-${props.row.id}`" triggers="hover" placement="top">
                    ยกเลิก
                </b-tooltip>
                        </div>
                    </template>       
                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Items per page
                            </span>
                            <b-form-select
                            v-model="pageLength"
                            :options="['10','20','50']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                        
                        </div>
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="Total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
            
        </b-card>
        <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
       
        filename="receipt.js"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a5"
        pdf-orientation="portrait"
        :paginate-elements-by-height="2480"
        :pdf-content-width="1754"
        @beforeDownload="beforeDownload($event)"
        
 
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
    <section slot="pdf-content" class="pdf-content text-black">
            <div class="m-2 text-center">
                 <b-img
                    src="@/assets/images/logo/viva-logo.png"
                    alt="logo"
                     style="height:75px;"
                     class="mb-1"
                    />
                <p class="text-black" style="font-size:8px;">ปิ่นเกล้า 083.6666.636 : BTS วงเวียนใหญ่ 084.8888.212</p>
                <h3 class=" text-black">วีว่าคลินิกเวชกรรม</h3>
                <h5 class=" text-black">การใช้คอร์ส {{ printData.first_name }}</h5>
                <hr />
                <div class="d-flex d-flex justify-content-between text-black">
                    <div >ชื่อ คุณ{{ printData.first_name }} {{ printData.last_name }}</div>
                    <div v-if="printData.date">วันที่ {{ formatDateTh(printData.date) }}</div>
                </div>
                <div class="d-flex d-flex justify-content-between text-black mt-1">
                    <div >OPD CARD NO <span class="pl-2">-</span></div>
                    <div>{{  printData.group_title }}</div>
                    <div>เลขที่บริการ	 {{  printData.service_no }}</div>
                </div>
            </div>
            <section class="pdf-item m-2 text-center "  >
               
               
                <table width="100%" style="font-size:10px;" class="text-black" cellpadding="5px" cellspacing="5px">
                    <thead style="margin:0px; padding:0px; border-top:1px solid #000; border-bottom:1px solid #000;">
                        <tr style="margin:0px; padding:0px;">
                            <th class="text-left" style="margin:0px; padding:0px;">รายการ</th>
                            <th class="text-left" style="margin:0px; padding:0px;">ราคา</th>
                            <th class="text-center" style="margin:0px; padding:0px;">จำนวน</th>
                            <th class="text-center" style="margin:0px; padding:0px;">%ส่วนลด</th>
                            <th class="text-right" style="margin:0px; padding:0px;">รวม</th>
                            <th class="text-right" style="margin:0px; padding:0px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in printData.items" :key="'item-'+item.id">
                            <td class="text-left">{{ item.title }}</td>
                            <td class="text-left"> {{  getCommaDecimal(item.price) }}</td>
                            <td class="text-center">
                                <span v-if="item.used > 0">{{ item.used }} unit</span>
                                <span v-else>{{ item.qty }}</span>
                            </td>
                            <td class="text-center"> 0 </td>
                            <td class="text-right">0</td>
                            <td>
                                <span v-if="item.voucher > 0">Voucher</span>
                            </td>
                        </tr>
                    </tbody>
                    <!--<tfoot style="margin:0px; padding:0px; border-top:1px solid #000; border-bottom:1px solid #000;">
                        <tr style="margin:0px; padding:0px;">
                            <th class="text-left" style="margin:0px; padding:0px;"></th>
                            <th class="text-left" style="margin:0px; padding:0px;"></th>
                            <th class="text-left" style="margin:0px; padding:0px;">รวม</th>
                         
                            <th class="text-right"  colspan="2" style="margin:0px; padding:0px;"> {{  getCommaDecimal((receipt.total_items-receipt.discount+(receipt.tax_value*1)).toFixed(2)) }}</th>
                            <th></th>
                        </tr>
                    </tfoot>-->

                </table>
                <div class="mt-1 text-left" style="font-size:10px;" >
                    
                        <div v-for="vc in vouchers" :key="'vc-'+vc.id">*** มูลค่า {{ vc.category_name }} คงเหลือ {{ getCommaDecimal(vc.balance.toFixed(2)) }} บาท ***</div>

                  
          
                        <div v-for="co in printData.courses" :key="'co-'+co.id">
                            <span v-if="co.product_count > 0">*** Course {{ co.title }} คงเหลือ {{  co.product_count }} CC *** วันหมดอายุ {{ formatDateTh(co.expire_date ) }}</span>
                            <span v-else>*** Course {{ co.title }} คงเหลือ {{  co.qty }} ครั้ง *** วันหมดอายุ {{ formatDateTh(co.expire_date ) }}</span>
                          
                        </div>


                </div>
               
            </section>
            <div class="m-2 d-flex align-items-center justify-content-between">
                            
                <div>จ่ายโดย 
                   เงินสด
                </div>
                 
                <div>วันที่พิมพ์ {{  formatDateThTime(DateNow) }}</div>
                            
            </div>
           
           
        </section>
        
        
    </vue-html2pdf>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker, BForm, BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useQueueServiceList from './useQueueServiceList'
import queueStoreModule from '../queueStoreModule'
import queueServiceStoreModule from '../queueServiceStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'

import { formatDateTh,formatDateThTime,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'
import {  watch, computed } from '@vue/composition-api'
import { VueAutosuggest } from 'vue-autosuggest'
import patientsStoreModule from '@/views/patients/patientsStoreModule'


import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'

  import receiptStoreModule from '@/views/receipt/receiptStoreModule'

import VueHtml2pdf from 'vue-html2pdf'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,
        VueGoodTable,
        VueAutosuggest,
        vSelect,
        BForm, BFormInvalidFeedback,
        ValidationProvider, ValidationObserver,
        VueHtml2pdf

    },
    watch:{
        doctor_id:function(val){
            this.inputData.doctor_id = val;
        }
    },
    mounted(){
        this.inputData.doctor_id = this.doctor_id;
    },
    data(){
        return {
            doctor_id:this.$route?.query.doctor,
            formatDateTh,
            formatDateToMonthShort,
            formatDateThTime,
            getCommaDecimal,
            sectionConfigs:{
                patients: {
                    limit: 6,
                   // label: 'Patients',
                        onSelected: selected => {
                            console.log('inputProps',selected);
                            this.patients = selected.item.first_name+' '+selected.item.last_name+'('+selected.item.code_member+')';
                            this.inputData.patients_id = selected.item.id;
                            console.log('inputData',this.inputData);
                            //this.selected = selected.item
                    },
                },
            }
            
        }
    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    methods:{
        renderSuggestion(suggestion){
            console.log('suggestion',suggestion);
            return suggestion.item.first_name+' '+suggestion.item.last_name+'('+suggestion.item.code_member+')'
        },
        async generateReport (id) {
            await this.getDetail(id);
            
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(event){
            console.log('event', event);
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                 //  pdf.setPage(i)
                   // pdf.setFontSize(10)
                    //pdf.setTextColor(150)
                   // pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).output('bloburl').then(r => { window.open(r) });
        },
        deleteconfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete QUEUE ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                this.cancelData(id)
                }
            
                //this.deleteData(id)
                })

        },
    },
    setup() {
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const QUEUE_STORE_MODULE_NAME = 'app-queue';
        if (!store.hasModule(QUEUE_STORE_MODULE_NAME)) store.registerModule(QUEUE_STORE_MODULE_NAME, queueStoreModule)
        onUnmounted(() => {
            if (store.hasModule(QUEUE_STORE_MODULE_NAME)) store.unregisterModule(QUEUE_STORE_MODULE_NAME)
        });
        const QUEUE_SERVICE_STORE_MODULE_NAME = 'queue-store';
        if (!store.hasModule(QUEUE_SERVICE_STORE_MODULE_NAME)) store.registerModule(QUEUE_SERVICE_STORE_MODULE_NAME, queueServiceStoreModule)
        onUnmounted(() => {
            if (store.hasModule(QUEUE_SERVICE_STORE_MODULE_NAME)) store.unregisterModule(QUEUE_SERVICE_STORE_MODULE_NAME)
        });
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
            if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
            onUnmounted(() => {
                if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
            });

        
        const {
            refListTable,
            tableColumns,
            items,
            currentPage,
            pageLength,
            Total,
           // sectionConfigs,
            inputProps,
            suggestions,
           // renderSuggestion,
            fetchResults,
            getSuggestionValue,
            patients,
            inputData,
            refFormObserver,
            Doctor_Data,
            Room_Data,
            onSubmit,
            resolveStatusVariant,
        resolveStatusTextVariant,
        searchQuery,
        searchDate,
        searchStatus,
        statuses,
        DateNow,
        getDetail,
        printData,
        vouchers,
        cancelData
            
        } = useQueueServiceList();


        return {
            refListTable,
            tableColumns,
            items,
            currentPage,
            pageLength,
            Total,
            //sectionConfigs,
            inputProps,
            suggestions,
           // renderSuggestion,
            fetchResults,
            getSuggestionValue,
            patients,
            inputData,
            refFormObserver,
            Doctor_Data,
            Room_Data,
            onSubmit,
            resolveStatusVariant,
        resolveStatusTextVariant,
        searchQuery,
        searchDate,
        searchStatus,
        statuses,
        DateNow,
        getDetail,
        printData,
        vouchers,
        cancelData

        }
    },
   
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>